import React, {Component} from "react";
import {
    Affix,
    Button,
    Divider,
    Spin,
    Select,
    Space,
    List,
    Card,
    Descriptions,
    Badge,
    Input,
    Tag,
    Switch,
    Segmented,
    message,
    Mentions,
    Row,
    Col,
    Empty, Table, Tabs, Tooltip, Popconfirm, Progress, Skeleton, Watermark,
} from "antd";
import {PageHeader} from "@ant-design/pro-layout";
import {
    AreaChartOutlined,
    BarChartOutlined,
    CaretRightOutlined,
    CheckSquareOutlined,
    CloseSquareOutlined, CloudSyncOutlined, CopyOutlined,
    createFromIconfontCN, DeleteOutlined,
    EditOutlined, GitlabOutlined, HistoryOutlined,
    OrderedListOutlined, PlusCircleOutlined, QuestionCircleOutlined,
    RetweetOutlined, SearchOutlined,
    SendOutlined, SettingOutlined, StopOutlined, SyncOutlined, UserOutlined
} from "@ant-design/icons";
import {Content} from "antd/es/layout/layout";
import {getUser, Loginout} from "../../Users/AuthRouter";
import {ProCard, CheckCard, Statistic} from "@ant-design/pro-components";
import {JsonTools} from "./JsonTools";
import {values} from "@antv/util";
import {CreateTT} from "./CreateTT";
import api from "../../../api/ApiConfig";
import Link from "antd/es/typography/Link";
import Avatar from "antd/es/avatar/avatar";
import Text from "antd/es/typography/Text";
import {TaskListBuildsTable} from "./TaskListBuildsTable";
import {TaskEnvConfig} from "./TaskEnvConfig";
import dayjs from "dayjs";
import {HelpPageHome} from "./HelpPageHome";
import {TaskListLocalTable} from "./TaskListLocalTable";

const {Option} = Mentions;

const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_1788592_a5xf2bdic3u.js', // icon-shoppingcart, icon-python
    ],
});

export class TaskList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            spinning: false,
            EditingStatus: false,
            CheckCardValue: null,
            TaskName: null,
            TaskType: null,
            TaskNote: null,
            TaskTimePlan: null,
            Markers: ["BLOCKER"],
            UserId: null,
            UserName: null,
            Users: null,
            userList: [],
            TaskConfig: {},
            JenkinsConfig: {},
            BuildsStatus: {},
            SwitchChecked: false,
            TestTaskList: [],
            delBtn: false,
            DatasDate: null,
            CasesList: [],
            TaskBuilds: [],
            BuildsPage: 1,
            BuildsPageSize: 10,
            CaseListPage: 1,
            CaseListPageSize: 20,
            executingtask_btn: false,
            TaskBuildsTotal: 0,
            CasesListTotal: 0,
            TabsActiveKey: "1",
            ExecuteNotes: null
        }
    }

    componentDidMount() {
        this.gettesttask()
        this.getuserlist_atmp()
    }

    userinfo = getUser()

    setBuildsPageSize = (Page, PageSize) => {
        this.setState({
            BuildsPage: Page,
            BuildsPageSize: PageSize
        }, function () {
            this.gettesttaskbuilds()
        })
    }

    synctaskbuildsstatus = async () => {
        let values = {}
        values['UserId'] = this.userinfo.UserId
        values['TaskId'] = this.state.CheckCardValue
        await api.SyncTaskBuildsStatus({values}).then(r => {
            if (r.msgcode === 200) {
                message.success("状态同步成功~")
                this.gettesttaskbuilds()
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
        })
    }
    getuserlist_atmp = async () => {
        await api.GetUserList_ATMP({}).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                let userList = []
                Object.entries(r['datas']).map(([key, value]) =>
                    userList.push({
                        value: `${value.name}(${value.user_id})`,
                        label: <Space size={5}>
                            <Avatar key={key} size={25} src={value.avatar_url}/>
                            <Text
                                style={{fontSize: 12, fontWeight: 600}}>{value.name}({value.user_id})</Text>
                        </Space>,
                        label1: <div>
                            <Tooltip title={<div>{value.name}({value.user_id})</div>} color={"#108ee9"} key={key}>
                                <Avatar key={key} size={30} src={value.avatar_url}/>
                            </Tooltip>
                        </div>
                    })
                )
                this.setState({
                    userList: userList
                })
            }
        })
    }
    gettesttaskcases = async () => {
        this.setState({
            spinning: true
        })
        let params = {}
        params['TaskId'] = this.state.CheckCardValue
        params['Page'] = this.state.CaseListPage
        params['PageSize'] = this.state.CaseListPageSize
        await api.GetTestTaskCases(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                this.setState({
                    CasesList: r['datas'],
                    CasesListTotal: r['total'],
                }, function () {

                })
            }
            setTimeout(() => {
                this.setState({
                    spinning: false
                })
            }, 200)
        })
    }
    gettesttaskbuilds = async () => {
        // this.setState({
        //     spinning: true
        // })
        let params = {}
        params['TaskId'] = this.state.CheckCardValue
        params['Page'] = this.state.BuildsPage
        params['PageSize'] = this.state.BuildsPageSize
        await api.GetTestTaskBuilds(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                this.setState({
                    TaskBuilds: r['datas'],
                    TaskBuildsTotal: r['total'],
                }, function () {

                })
            }
            // this.setState({
            //     spinning: false
            // })
        })
    }
    gettesttaskconfig = async () => {
        let params = {}
        params['TaskId'] = this.state.CheckCardValue
        values['UserId'] = this.userinfo.UserId
        await api.GetTestTaskConfig(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else if (r.msgcode === 200) {
                this.setState({
                    JenkinsConfig: {},
                }, function () {
                })
                this.setState({
                    JenkinsConfig: r['datas'],
                }, function () {
                })
            }
        })
    }
    deltesttaskcases = async (caseId, delType) => {
        this.setState({
            spinning: true
        })
        let values = {}
        values['UserId'] = this.userinfo.UserId
        if (caseId != null) {
            values['caseId'] = caseId
        }
        values['TaskId'] = this.state.CheckCardValue
        values['delType'] = delType
        await api.PostTestTaskCases({values}).then(r => {
            if (r.msgcode === 200) {
                message.success("删除测试用例成功~")
                this.gettesttaskcases()
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
            this.setState({
                spinning: false
            })
        })
    }
    executingtask = async () => {
        this.setState({
            executingtask_btn: true,
            spinning: true
        })
        let values = {}
        values['UserId'] = this.userinfo.UserId
        values['TaskId'] = this.state.CheckCardValue
        if (this.state.ExecuteNotes != null) {
            values['ExecuteNotes'] = this.state.ExecuteNotes
        }
        await api.ExecutingTask({values}).then(r => {
            if (r.msgcode === 200) {
                message.success("任务下发成功~")
                this.gettesttaskbuilds()
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
            setTimeout(() => {
                this.setState({
                    executingtask_btn: false
                })
            }, 2000)
            this.setState({
                spinning: false
            })
        })
    }
    stoptesttask = async () => {
        this.setState({
            spinning: true
        })
        let values = {}
        values['UserId'] = this.userinfo.UserId
        values['TaskId'] = this.state.CheckCardValue
        await api.StopTestTask({values}).then(r => {
            if (r.msgcode === 200) {
                message.success("终止指令已下发!")
                this.gettesttaskbuilds()
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
        })
        setTimeout(() => {
            this.setState({
                spinning: false
            })
        }, 200)
    }
    deltesttask = async (TaskId) => {
        this.setState({
            spinning: true
        })
        let values = {}
        values['UserId'] = this.userinfo.UserId
        values['TaskId'] = TaskId
        await api.DelTestTask({values}).then(r => {
            if (r.msgcode === 200) {
                message.success("测试任务,删除成功!")
                setTimeout(() => {
                    this.gettesttask()
                }, 1000)
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
            setTimeout(() => {
                this.setState({
                    delBtn: false
                })
            }, 1000)
            this.setState({
                spinning: false
            })
        })

    }
    deltesttaskbuilds = async () => {
        this.setState({
            spinning: true
        })
        let values = {}
        values['UserId'] = this.userinfo.UserId
        values['TaskId'] = this.state.CheckCardValue
        await api.DelTestTaskBuilds({values}).then(r => {
            if (r.msgcode === 200) {
                message.success("删除构建任务成功!")
                this.gettesttaskbuilds()
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
        })
        setTimeout(() => {
            this.setState({
                spinning: false
            })
        }, 300)
    }
    gettesttask = async (params = {}) => {
        this.setState({
            spinning: true
        })
        params['userid'] = this.userinfo.UserId
        params['ProjectId'] = this.props.match.params.id
        await api.GetTestTask(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else if (r.msgcode === 200) {
                message.success("获取最新的任务数据成功～")
                if (r.datas.length > 0) {
                    let TC = {}
                    try {
                        TC = JSON.parse(r.datas[0].fields.TaskConfig)
                    } catch (e) {
                        console.log("Json字符串格式错误", r.datas[0].fields.TaskConfig)
                        TC = r.datas[0].fields.TaskConfig
                    }
                    this.setState({
                        TestTaskList: r.datas,
                        CheckCardValue: r.datas[0].pk,
                        UserId: r.datas[0].fields.UserId,
                        UserName: r.datas[0].fields.UserName,
                        TaskName: r.datas[0].fields.TaskName,
                        TaskType: r.datas[0].fields.TaskType,
                        TaskNote: r.datas[0].fields.TaskNote,
                        Markers: JSON.parse(r.datas[0].fields.Markers),
                        Users: JSON.parse(r.datas[0].fields.Users),
                        TaskConfig: TC,
                        TaskTimePlan: r.datas[0].fields.TaskTimePlan,
                        DatasDate: r.datas[0].fields.DatasDate,
                        SwitchChecked: r.datas[0].fields.ProjectTest !== 2,
                        delBtn: false,
                    }, function () {
                        this.gettesttaskcases()
                        this.gettesttaskbuilds()
                        this.gettesttaskconfig()
                    })
                }
            } else {
                this.setState({
                    TestTaskList: [],
                    delBtn: true
                })
            }
            setTimeout(() => {
                this.setState({
                    spinning: false
                })
            }, 200)
        })
    }
    posttesttask = async () => {
        let values = {}
        if (this.state.TaskName.length === 0 || this.state.TaskNote.length === 0 || this.state.Users.length === 0) {
            message.error("任务名称、任务描述、相关人员字段不可为空～")
            return false
        }
        if (this.state.Markers.length === 0) {
            message.error("用例执行等级字段不可为空～")
            return false
        }
        values['ProjectId'] = this.props.match.params.id
        values['TaskId'] = this.state.CheckCardValue
        values['UserId'] = this.userinfo.UserId
        values['UserName'] = this.userinfo.username
        values['TaskName'] = this.state.TaskName
        values['TaskType'] = this.state.TaskType
        values['TaskNote'] = this.state.TaskNote
        values['TaskTimePlan'] = this.state.TaskTimePlan
        values['Markers'] = JSON.stringify(this.state.Markers)
        values['Users'] = JSON.stringify(this.state.Users)
        values['ProjectTest'] = this.state.SwitchChecked === true ? 1 : 2
        let TC = {}
        try {
            typeof this.state.TaskConfig == "object" ? JSON.stringify(this.state.TaskConfig) : JSON.parse(this.state.TaskConfig)
            TC = typeof this.state.TaskConfig == "object" ? JSON.stringify(this.state.TaskConfig) : this.state.TaskConfig
            this.setState({
                EditingStatus: false
            })
        } catch (e) {
            console.log("保存时Json字符串格式错误", this.state.TaskConfig)
            TC = false
        }
        if (TC !== false) {
            values['TaskConfig'] = TC
        } else {
            message.error("json格式错误,请根据提示修改后再操作保存！～")
            return false
        }
        await api.PostTestTask({values}).then(r => {
            if (r.msgcode === 200) {
                message.success("配置修改成功")
                this.setState({
                    isModalOpen: false,
                })
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
            this.gettesttask()
        })
    }

    render() {
        const tagRender = (props) => {
            const {label, value, closable, onClose} = props;
            const onPreventMouseDown = (event) => {
                event.preventDefault();
                event.stopPropagation();
            };
            return (
                <Tag
                    color={
                        label === "BLOCKER" ? "#001d66" :
                            label === "CRITICAL" ? "#003eb3" :
                                label === "NORMAL" ? "#1677ff" :
                                    label === "MINOR" ? "#69b1ff" :
                                        label === "TRIVIAL" ? "#bae0ff" :
                                            label === "STABILITY" ? "#237804" :
                                                label === "DEBUG" ? "#595959" :
                                                    "#9254de"
                    }
                    onMouseDown={onPreventMouseDown}
                    closable={closable}
                    onClose={onClose}
                    style={{
                        marginRight: 5,
                    }}
                >
                    {label}
                </Tag>
            );
        };
        const CaseListColumns = [
            {
                title: 'No.',
                key: '1',
                fixed: 'left',
                width: 60,
                align: 'center',
                render: (record, text, index) => {
                    return (index + 1)
                }
            },
            {
                title: '分配时间',
                width: 180,
                dataIndex: 'DatasDate',
                align: 'center',
                render: (record, text, index) => {
                    return <div style={{fontSize: 12, fontWeight: 600}}>
                        {dayjs(record).format("YYYY-MM-DD HH:mm:ss")}
                    </div>
                }
            },

            {
                title: '用例信息',
                dataIndex: 'func_doc',
                align: 'center',
                ellipsis: true,
                render: (record, text, index) => {
                    return <div style={{textAlign: "left"}}>
                        <Space direction={"vertical"} size={1}>
                            <Space>
                                <div style={{fontFamily: "Roboto, sans-serif", fontSize: 11, fontWeight: 800}}>
                                    用例路径：
                                </div>
                                <div style={{
                                    color: "#434343",
                                    fontFamily: "Roboto, sans-serif",
                                    fontSize: 11,
                                    fontWeight: 800
                                }}>{text.file_path}</div>
                            </Space>
                            <Space>
                                <div style={{fontFamily: "Roboto, sans-serif", fontSize: 11, fontWeight: 800}}>
                                    用例类名：
                                </div>
                                <div style={{
                                    color: "#237804",
                                    fontFamily: "Roboto, sans-serif",
                                    fontSize: 11,
                                    fontWeight: 800
                                }}>{text.class_name}</div>
                            </Space>
                            <Space>
                                <div style={{fontFamily: "Roboto, sans-serif", fontSize: 11, fontWeight: 800}}>
                                    用例类名：
                                </div>
                                <div style={{
                                    color: "#237804",
                                    fontFamily: "Roboto, sans-serif",
                                    fontSize: 11,
                                    fontWeight: 800
                                }}>{text.func_name}</div>
                            </Space>
                            <Space>
                                <div style={{fontFamily: "Roboto, sans-serif", fontSize: 11, fontWeight: 800}}>
                                    用例描述：
                                </div>
                                <div style={{
                                    color: "#002329",
                                    fontFamily: "Roboto, sans-serif",
                                    fontSize: 11,
                                    fontWeight: 800
                                }}>{record}</div>
                            </Space>
                        </Space>
                    </div>
                }
            },
            {
                title: '用例作者',
                width: 170,
                dataIndex: 'user_name',
                align: 'center',
                render: (record, text, index) => {
                    return <div style={{textAlign: "left"}}>
                        <Space style={{fontSize: 12}}>
                            <Avatar shape="square"
                                    size={28}
                                    style={{
                                        backgroundColor: '#024605',
                                    }}
                                    icon={<UserOutlined/>}
                            >
                            </Avatar>
                            {record.split("@")[1]}
                        </Space>
                    </div>
                }
            },
            {
                title: '用例等级',
                width: 120,
                dataIndex: 'case_mark',
                align: 'center',
                render: (record, text, index) => {
                    return <div style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontSize: 12,
                        color: "rgb(112,114,114)"
                    }}>
                        <Tag color={"geekblue"}>{record}</Tag>
                    </div>
                }
            },
            {
                title: '数据驱动',
                align: 'center',
                dataIndex: 'test_datas',
                // width: 400,
                ellipsis: true,
                render: (record, text, index) => {
                    let record_arr = []
                    try {
                        record_arr = JSON.parse(record.replace(/'/g, '"') // 替换单引号为双引号
                            .replace(/True/g, 'true') // 替换 True 为 true
                            .replace(/False/g, 'false') // 替换 False 为 false
                            .replace(/None/g, 'null') // 替换 None 为 null
                        )
                    } catch (e) {
                        record_arr = []
                        console.log(`数据驱动解析失败:${e} 原始数据:${record}`);
                    }
                    return <div>
                        {Array.isArray(record_arr) && record_arr.length > 0
                            ? record_arr.map((s, index) => {
                                let str_1 = "";
                                try {
                                    // 可能会引发异常的代码块
                                    if (typeof s === 'object') {
                                        str_1 = JSON.stringify(s);
                                    } else {
                                        str_1 = s;
                                    }
                                } catch (error) {
                                    // 异常发生时执行的代码块
                                    str_1 = JSON.stringify(s);
                                }
                                return <div style={{
                                    maxWidth: 500,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    textAlign: "left",
                                    fontSize: 11,
                                    color: "rgb(112,114,114)",
                                }}>
                                    {index === 0 ? "用例编号" :
                                        index === 1 ? "用例名称" :
                                            index === 2 ? "测试数据" :
                                                index === 3 ? "期望结果" : "未知参数"}: {str_1}
                                </div>;
                            })
                            : "-"}
                    </div>
                }
            },
            {
                title: '用例操作',
                fixed: 'right',
                width: 120,
                align: 'center',
                dataIndex: 'key',
                render: (record, text, index) => {
                    return <div>
                        <Popconfirm
                            style={{fontSize: 12}}
                            title={<div style={{fontSize: 12}}>删除数据</div>}
                            description={<div style={{fontSize: 12}}>您确定要移除任务中的此条用例吗？</div>}
                            onConfirm={() => {
                                console.log("删除用例", record)
                                this.deltesttaskcases(record, 1)
                            }}
                            okText={<div style={{fontSize: 12}}>确认</div>}
                            cancelText={<div style={{fontSize: 12}}>取消</div>}
                        >
                            <Button
                                style={{fontSize: 12}}
                                type="link" danger
                                size={"small"}
                                icon={<DeleteOutlined/>}
                            >删除用例</Button>
                        </Popconfirm>
                    </div>
                }
            },
        ];
        return <>
            <Affix offsetTop={60}>
                <div style={{
                    background: '#ffffff',
                    textAlign: 'center',
                }}>
                    <PageHeader
                        className="site-page-header-responsive"
                        title="测试任务"
                        subTitle={"测试任务管理"}
                        onBack={() => window.history.back()}
                        extra={<>
                            <div>
                                <Divider type="vertical"/>
                                <Button type={'link'}
                                        style={{width: 100, color: '#707272', fontWeight: 800}}
                                        onClick={() => {
                                            this.gettesttask()
                                            this.getuserlist_atmp()
                                        }}>
                                    <Space size={5} style={{fontWeight: 800, fontSize: 13}}>
                                        <RetweetOutlined/>
                                        <div style={{marginTop: -2}}>刷新数据</div>
                                    </Space>
                                </Button>
                                <Divider type="vertical"/>
                            </div>
                        </>
                        }
                    >
                        <Content/>
                    </PageHeader>
                </div>
            </Affix>
            <Watermark content="公牛Bull">
                <Spin style={{marginTop: "10%"}} tip="数据加载中....." fullscreen={"true"} spinning={this.state.spinning}>
                    <div style={{
                        marginTop: 3,
                        background: 'rgb(240, 242, 245)',
                        minHeight: 763,
                    }}>
                        {this.state.TestTaskList.length > 0 ?
                            <div>
                                <Row style={{
                                    width: '100%',
                                    display: 'flex',
                                    height: '100%',
                                    minHeight: "85vh",
                                }} wrap={false}>
                                    <Col span={5}>
                                        <Card
                                            style={{
                                                minHeight: "100%"
                                            }}
                                            bordered={true}
                                            title={<div style={{height: 25}}>任务列表</div>} type={"inner"}
                                            extra={<Space size={1} style={{height: 25}}>
                                                <CreateTT btn_type={true} datas={{
                                                    ProjectId: this.props.match.params.id
                                                }} fun1={this.gettesttask}/>
                                                <Popconfirm
                                                    title="删除测试任务"
                                                    description={<div style={{
                                                        fontSize: 12,
                                                        color: "red"
                                                    }}>确定要删除该测试任务以及相关的所有配置数据吗?</div>}
                                                    onConfirm={() => {
                                                        this.setState({
                                                            delBtn: true
                                                        }, function () {
                                                            this.deltesttask(this.state.CheckCardValue)
                                                        })
                                                    }}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    <Button size={"small"} disabled={this.state.delBtn}
                                                            type={"link"} style={{width: 70, marginLeft: 10}} danger>
                                                        <Space size={5}>
                                                            <DeleteOutlined/>
                                                            <div style={{fontWeight: 800, fontSize: 12}}>删除任务</div>
                                                        </Space>
                                                    </Button>
                                                </Popconfirm>
                                            </Space>}>
                                            <CheckCard.Group
                                                style={{
                                                    width: "100%",
                                                    overflow: 'auto',
                                                    padding: 15,
                                                    maxHeight: 1000,
                                                }}
                                                value={this.state.CheckCardValue}
                                                onChange={(value) => {
                                                    if (typeof value !== "undefined") {
                                                        for (let i = 0; i <= this.state.TestTaskList.length; i++) {
                                                            if (this.state.TestTaskList[i].pk === value) {
                                                                let TC = {}
                                                                try {
                                                                    TC = JSON.parse(this.state.TestTaskList[i].fields.TaskConfig)
                                                                } catch (e) {
                                                                    console.log("Json字符串格式错误", this.state.TestTaskList[i].fields.TaskConfig)
                                                                    TC = this.state.TestTaskList[i].fields.TaskConfig
                                                                }
                                                                this.setState({
                                                                    CheckCardValue: value,
                                                                    UserId: this.state.TestTaskList[i].fields.UserId,
                                                                    UserName: this.state.TestTaskList[i].fields.UserName,
                                                                    EditingStatus: false,
                                                                    TaskName: this.state.TestTaskList[i].fields.TaskName,
                                                                    TaskType: this.state.TestTaskList[i].fields.TaskType,
                                                                    TaskNote: this.state.TestTaskList[i].fields.TaskNote,
                                                                    Markers: JSON.parse(this.state.TestTaskList[i].fields.Markers),
                                                                    Users: JSON.parse(this.state.TestTaskList[i].fields.Users),
                                                                    TaskConfig: TC,
                                                                    TaskTimePlan: this.state.TestTaskList[i].fields.TaskTimePlan,
                                                                    DatasDate: this.state.TestTaskList[i].fields.DatasDate,
                                                                    SwitchChecked: this.state.TestTaskList[i].fields.ProjectTest !== 2,
                                                                }, function () {
                                                                    this.gettesttaskcases()
                                                                    this.gettesttaskconfig()
                                                                    this.gettesttaskbuilds()
                                                                })
                                                                break
                                                            }
                                                        }
                                                    }
                                                }}
                                                size={"default"}
                                            >
                                                {this.state.TestTaskList.map((items, index, array) => {
                                                    return <div key={items.pk}>
                                                        <Badge.Ribbon key={items.pk} placement={"start"}
                                                                      text={index + 1}
                                                                      color="#002766">
                                                            <CheckCard
                                                                style={{borderRadius: 10, height: 120, width: '99%'}}
                                                                key={items.pk}
                                                                title={
                                                                    <div style={{marginLeft: 2, fontSize: 13}}>
                                                                        {items.fields.TaskName}
                                                                    </div>
                                                                }
                                                                description={
                                                                    <div style={{
                                                                        padding: 5,
                                                                        fontSize: 12,
                                                                        textAlign: "left"
                                                                    }}>
                                                                        {
                                                                            <div
                                                                                style={{
                                                                                    fontSize: 11,
                                                                                    height: 22,
                                                                                    fontWeight: 700
                                                                                }}>
                                                                                {
                                                                                    items.fields.TaskType === 1 ?
                                                                                        <Tag
                                                                                            color="#2db7f5">Devices</Tag> :
                                                                                        items.fields.TaskType === 2 ?
                                                                                            <Tag
                                                                                                color="#87d068">Interface</Tag> :
                                                                                            items.fields.TaskType === 3 ?
                                                                                                <Tag
                                                                                                    color="#722ed1">Appium</Tag> :
                                                                                                items.fields.TaskType === 4 ?
                                                                                                    <Tag
                                                                                                        color="#434343">Selenium</Tag> :
                                                                                                    items.fields.TaskType === 5 ?
                                                                                                        <Tag
                                                                                                            color="#254000">Python</Tag> :
                                                                                                        items.fields.TaskType === 6 ?
                                                                                                            <Tag
                                                                                                                color="#237804">Stability</Tag> :
                                                                                                            items.fields.TaskType === 7 ?
                                                                                                                <Tag
                                                                                                                    color="#fa8c16">Local</Tag> : null}
                                                                            </div>
                                                                        }
                                                                        <div style={{marginTop: 5}}>
                                                                            {items.fields.TaskNote}
                                                                        </div>
                                                                    </div>
                                                                }
                                                                value={items.pk}
                                                            />
                                                        </Badge.Ribbon>
                                                    </div>
                                                })
                                                }
                                            </CheckCard.Group>
                                        </Card>
                                    </Col>
                                    <Col span={19}>
                                        <Card title={<div style={{height: 25}}>任务详情</div>}
                                            // extra={}
                                              type={"inner"}
                                              style={{minHeight: "100%"}}
                                        >
                                            <Tabs
                                                tabBarExtraContent={
                                                    <div>
                                                        {this.state.TaskType === 7 ? null :
                                                            <Space size={5}>
                                                                <Divider type="vertical"/>
                                                                <Popconfirm
                                                                    placement={"leftBottom"}
                                                                    title="执行原因"
                                                                    description={
                                                                        <div>
                                                                            <Input.TextArea
                                                                                value={this.state.ExecuteNotes}
                                                                                placeholder={"请输入执行原因 如:xxx版本提测"}
                                                                                onChange={(e) => {
                                                                                    this.setState({
                                                                                        ExecuteNotes: e.target.value.length === 0 ? null : e.target.value
                                                                                    })
                                                                                }}/>
                                                                        </div>
                                                                    }
                                                                    onCancel={() => {
                                                                        this.setState({
                                                                            Popconfirm: false,
                                                                            ExecuteNotes: null
                                                                        })
                                                                    }}
                                                                    onConfirm={() => {
                                                                        if (this.state.ExecuteNotes != null) {
                                                                            this.executingtask()
                                                                            this.setState({
                                                                                TabsActiveKey: "3",
                                                                                BuildsPage: 1,
                                                                                Popconfirm: false,
                                                                                ExecuteNotes: null
                                                                            })
                                                                        } else {
                                                                            message.warning("请输入执行描述")
                                                                        }
                                                                    }}
                                                                    open={this.state.Popconfirm}
                                                                >
                                                                    <Button type={"link"}
                                                                            style={{
                                                                                width: 80,
                                                                                color: '#1677ff',
                                                                                fontWeight: 800,
                                                                                fontSize: 12
                                                                            }}
                                                                            size={"small"}
                                                                            disabled={this.state.executingtask_btn}
                                                                            onClick={() => {
                                                                                this.setState({
                                                                                    Popconfirm: true
                                                                                })
                                                                            }}>
                                                                        <Space size={5} style={{
                                                                            fontWeight: 800,
                                                                            fontSize: 13
                                                                        }}>
                                                                            <SendOutlined/>
                                                                            <div>执行任务</div>
                                                                        </Space>
                                                                    </Button>
                                                                </Popconfirm>
                                                                <Divider type="vertical"/>
                                                                <Button type={"link"}
                                                                        style={{
                                                                            width: 80,
                                                                            color: '#e02f2f',
                                                                        }}
                                                                        size={"small"}
                                                                        onClick={() => {
                                                                            this.stoptesttask()
                                                                        }}>
                                                                    <Space size={5} style={{
                                                                        fontWeight: 800,
                                                                        fontSize: 13
                                                                    }}>
                                                                        <StopOutlined/>
                                                                        <div>终止任务</div>
                                                                    </Space>
                                                                </Button>
                                                                <Divider type="vertical"/>
                                                            </Space>
                                                        }
                                                    </div>
                                                }
                                                type="card"
                                                centered={false}
                                                defaultActiveKey={this.state.TabsActiveKey}
                                                activeKey={this.state.TabsActiveKey}
                                                onChange={(activeKey) => {
                                                    this.setState({
                                                        TabsActiveKey: activeKey
                                                    })
                                                    if (activeKey === "2") {
                                                        this.gettesttaskcases()
                                                    }
                                                    if (activeKey === "3") {
                                                        this.gettesttaskbuilds()
                                                    }
                                                    if (activeKey === "4") {
                                                        this.gettesttaskconfig()
                                                    }
                                                }}
                                                items={[
                                                    {
                                                        key: '1',
                                                        label: (<Space size={1}><SettingOutlined/>
                                                            <div style={{
                                                                marginLeft: -5,
                                                                fontWeight: 800
                                                            }}>任务配置
                                                            </div>
                                                        </Space>),
                                                        children: <Card
                                                            // title={<div style={{height: 25}}>配置详情</div>}
                                                            // type={"inner"}
                                                            bordered={true}
                                                            style={{
                                                                flexGrowth: 1,
                                                                height: '100%',
                                                                width: '100%',
                                                                minHeight: 763
                                                            }}
                                                            extra={<Space style={{height: 25}}>
                                                                {!this.state.EditingStatus ?
                                                                    <Button type={"link"}
                                                                            style={{
                                                                                width: 90,
                                                                                color: '#707272'
                                                                            }}
                                                                            size={"small"}
                                                                            onClick={() => {
                                                                                this.setState({
                                                                                    EditingStatus: true
                                                                                })
                                                                            }}>
                                                                        <Space size={5}>
                                                                            <EditOutlined/>
                                                                            <div
                                                                                style={{
                                                                                    fontWeight: 800,
                                                                                    fontSize: 12
                                                                                }}>编辑配置
                                                                            </div>
                                                                        </Space>
                                                                    </Button> :
                                                                    <Button type={"link"}
                                                                            style={{
                                                                                width: 100,
                                                                                color: '#707272'
                                                                            }}
                                                                            size={"small"}
                                                                            onClick={() => {
                                                                                for (let i = 0; i <= this.state.TestTaskList.length; i++) {
                                                                                    if (this.state.TestTaskList[i].pk === this.state.CheckCardValue) {
                                                                                        let TC = {}
                                                                                        try {
                                                                                            TC = JSON.parse(this.state.TestTaskList[i].fields.TaskConfig)
                                                                                        } catch (e) {
                                                                                            console.log("Json字符串格式错误", this.state.TestTaskList[i].fields.TaskConfig)
                                                                                            TC = this.state.TestTaskList[i].fields.TaskConfig
                                                                                        }
                                                                                        this.setState({
                                                                                            EditingStatus: false,
                                                                                            TaskName: this.state.TestTaskList[i].fields.TaskName,
                                                                                            TaskType: this.state.TestTaskList[i].fields.TaskType,
                                                                                            TaskNote: this.state.TestTaskList[i].fields.TaskNote,
                                                                                            Markers: JSON.parse(this.state.TestTaskList[i].fields.Markers),
                                                                                            Users: JSON.parse(this.state.TestTaskList[i].fields.Users),
                                                                                            TaskConfig: TC,
                                                                                            TaskTimePlan: this.state.TestTaskList[i].fields.TaskTimePlan,
                                                                                            SwitchChecked: this.state.TestTaskList[i].fields.ProjectTest !== 2,
                                                                                        })
                                                                                        break
                                                                                    }
                                                                                }
                                                                            }}>
                                                                        <Space size={5}>
                                                                            <CloseSquareOutlined/>
                                                                            <div
                                                                                style={{
                                                                                    fontWeight: 800,
                                                                                    fontSize: 12
                                                                                }}>取消编辑
                                                                            </div>
                                                                        </Space>
                                                                    </Button>}
                                                                <Divider type="vertical"/>
                                                                <Button type={"link"}
                                                                        style={{
                                                                            width: 90,
                                                                            color: '#707272'
                                                                        }}
                                                                        size={"small"}
                                                                        disabled={!this.state.EditingStatus}
                                                                        onClick={() => {
                                                                            this.posttesttask()
                                                                        }}>
                                                                    <Space size={5}>
                                                                        <CheckSquareOutlined/>
                                                                        <div
                                                                            style={{
                                                                                fontWeight: 800,
                                                                                fontSize: 12
                                                                            }}>提交配置
                                                                        </div>
                                                                    </Space>
                                                                </Button>
                                                                <Divider type="vertical"/>
                                                            </Space>}
                                                        >
                                                            <Badge.Ribbon text="测试任务配置" color="#3f6600">
                                                                <Descriptions bordered={true}
                                                                              size={"small"}
                                                                              contentStyle={{textAlign: "center"}}
                                                                              labelStyle={{
                                                                                  width: 120,
                                                                                  fontWeight: 800,
                                                                                  height: 70
                                                                              }}
                                                                    // layout={"vertical"}
                                                                              style={{textAlign: "center"}}
                                                                >
                                                                    <Descriptions.Item label="项目名称"
                                                                                       span={3}>{this.props.match.params.name}</Descriptions.Item>
                                                                    <Descriptions.Item label="任务名称">
                                                                        {this.state.EditingStatus ?
                                                                            <Input
                                                                                maxLength={40}
                                                                                minLength={1}
                                                                                showCount
                                                                                value={this.state.TaskName}
                                                                                onChange={(e) => {
                                                                                    this.setState({
                                                                                        TaskName: e.target.value
                                                                                    })
                                                                                }}/> : this.state.TaskName}
                                                                    </Descriptions.Item>
                                                                    <Descriptions.Item label="任务类型">
                                                                        {this.state.EditingStatus ?
                                                                            <Select
                                                                                showArrow={this.state.EditingStatus}
                                                                                showSearch={false}
                                                                                autoFocus={this.state.EditingStatus}
                                                                                disabled={!this.state.EditingStatus}
                                                                                bordered={this.state.EditingStatus}
                                                                                tagRender={tagRender}
                                                                                placeholder=""
                                                                                // disabled={true}
                                                                                value={this.state.TaskType}
                                                                                onChange={(value, option) => {
                                                                                    this.setState({
                                                                                        TaskType: value
                                                                                    })
                                                                                }}
                                                                                options={[
                                                                                    {
                                                                                        value: 1,
                                                                                        label: '设备自动化',
                                                                                    },
                                                                                    {
                                                                                        value: 2,
                                                                                        label: '接口自动化',
                                                                                    },
                                                                                    {
                                                                                        value: 3,
                                                                                        label: 'App自动化',
                                                                                    },
                                                                                    {
                                                                                        value: 4,
                                                                                        label: 'Web自动化',
                                                                                    },
                                                                                    {
                                                                                        value: 5,
                                                                                        label: '自定义任务',
                                                                                    },
                                                                                    {
                                                                                        value: 6,
                                                                                        label: '稳定性任务',
                                                                                    },
                                                                                    {
                                                                                        value: 7,
                                                                                        label: '本地化任务',
                                                                                    }
                                                                                ]}
                                                                            /> :
                                                                            <div
                                                                                style={{
                                                                                    fontSize: 12,
                                                                                    fontWeight: 800
                                                                                }}>
                                                                                {this.state.TaskType === 1 ?
                                                                                    <Tag
                                                                                        color={"blue-inverse"}
                                                                                        icon={<IconFont
                                                                                            type="icon-python"/>}>设备自动化</Tag> :
                                                                                    this.state.TaskType === 2 ?
                                                                                        <Tag icon={
                                                                                            <IconFont
                                                                                                type="icon-python"/>}
                                                                                             color="#87d068">接口自动化</Tag> :
                                                                                        this.state.TaskType === 3 ?
                                                                                            <Tag icon={
                                                                                                <IconFont
                                                                                                    type="icon-python"/>}
                                                                                                 color="#531dab">App自动化</Tag> :
                                                                                            this.state.TaskType === 4 ?
                                                                                                <Tag
                                                                                                    icon={
                                                                                                        <IconFont
                                                                                                            type="icon-python"/>}
                                                                                                    color="#000000">Web自动化</Tag> :
                                                                                                this.state.TaskType === 5 ?
                                                                                                    <Tag
                                                                                                        icon={
                                                                                                            <IconFont
                                                                                                                type="icon-python"/>}
                                                                                                        color="#254000">自定义任务</Tag> :
                                                                                                    this.state.TaskType === 6 ?
                                                                                                        <Tag
                                                                                                            icon={
                                                                                                                <IconFont
                                                                                                                    type="icon-python"/>}
                                                                                                            color="#237804">稳定性任务</Tag> :
                                                                                                        this.state.TaskType === 7 ?
                                                                                                            <Tag
                                                                                                                icon={
                                                                                                                    <IconFont
                                                                                                                        type="icon-python"/>}
                                                                                                                color="#fa8c16">本地化任务</Tag> :
                                                                                                            <Tag
                                                                                                                icon={
                                                                                                                    <IconFont
                                                                                                                        type="icon-python"/>}
                                                                                                                color="#874d00">未知类型</Tag>
                                                                                }
                                                                            </div>

                                                                        }
                                                                    </Descriptions.Item>
                                                                    <Descriptions.Item label="用例数量">
                                                                        <Tag color={"#531dab"}
                                                                             style={{
                                                                                 fontSize: 13,
                                                                                 fontWeight: 800
                                                                             }}>
                                                                            {this.state.CasesListTotal}
                                                                        </Tag>
                                                                    </Descriptions.Item>
                                                                    <Descriptions.Item label="任务描述"
                                                                                       span={2}>
                                                                        {this.state.EditingStatus ?
                                                                            <Input
                                                                                maxLength={50}
                                                                                minLength={1}
                                                                                showCount
                                                                                value={this.state.TaskNote}
                                                                                onChange={(e) => {
                                                                                    this.setState({
                                                                                        TaskNote: e.target.value
                                                                                    })
                                                                                }}/> : this.state.TaskNote}
                                                                    </Descriptions.Item>
                                                                    <Descriptions.Item label="定时计划"
                                                                                       span={1}>
                                                                        {this.state.EditingStatus ?
                                                                            <Input
                                                                                maxLength={30}
                                                                                showCount
                                                                                value={this.state.TaskTimePlan === null || this.state.TaskTimePlan.length === 0 ? '' : this.state.TaskTimePlan}
                                                                                onChange={(e) => {
                                                                                    this.setState({
                                                                                        TaskTimePlan: e.target.value
                                                                                    })
                                                                                }}/> :
                                                                            <Space size={10}>
                                                                                <div>
                                                                                    {this.state.TaskTimePlan === null || this.state.TaskTimePlan.length === 0 ? "暂无定时" : this.state.TaskTimePlan}
                                                                                </div>
                                                                                <div
                                                                                    style={{
                                                                                        fontSize: 11,
                                                                                        fontWeight: 700,
                                                                                        color: "indianred"
                                                                                    }}>
                                                                                    (Ps:请使用 <Link
                                                                                    target="_blank"
                                                                                    href="https://baike.baidu.com/item/cron/10952601?fr=ge_ala">
                                                                                    Cron<SearchOutlined/>
                                                                                </Link> 表达式)
                                                                                </div>
                                                                            </Space>
                                                                        }
                                                                    </Descriptions.Item>
                                                                    <Descriptions.Item label="任务属主">
                                                                        {([`${this.state.UserName}(${this.state.UserId})`]).map((value, index) => {
                                                                            for (let i = 0; i < this.state.userList.length; i++) {
                                                                                if (value === this.state.userList[i].value) {
                                                                                    return this.state.userList[i].label
                                                                                }
                                                                            }
                                                                        })}
                                                                    </Descriptions.Item>
                                                                    <Descriptions.Item label="项目提测"
                                                                                       span={1}>
                                                                        <Switch checkedChildren="是"
                                                                                unCheckedChildren="否"
                                                                                checked={this.state.SwitchChecked}
                                                                                disabled={!this.state.EditingStatus}
                                                                                onChange={(checked) => {
                                                                                    this.setState({
                                                                                        SwitchChecked: checked
                                                                                    })
                                                                                }}
                                                                        />
                                                                        {/*<Segmented options={['是', '否']}  disabled={!this.state.EditingStatus}/>*/}
                                                                    </Descriptions.Item>
                                                                    <Descriptions.Item
                                                                        label="操作时间"> {this.state.DatasDate} </Descriptions.Item>
                                                                    <Descriptions.Item label="执行等级"
                                                                                       span={3}
                                                                                       style={{textAlign: "left"}}
                                                                    >
                                                                        <Select
                                                                            style={{minWidth: 120}}
                                                                            mode="multiple"
                                                                            showArrow={this.state.EditingStatus}
                                                                            showSearch={false}
                                                                            autoFocus={this.state.EditingStatus}
                                                                            disabled={!this.state.EditingStatus}
                                                                            bordered={this.state.EditingStatus}
                                                                            tagRender={tagRender}
                                                                            placeholder=""
                                                                            // disabled={true}
                                                                            value={this.state.Markers}
                                                                            onChange={(value, option) => {
                                                                                this.setState({
                                                                                    Markers: value
                                                                                })
                                                                            }}
                                                                            options={["BLOCKER", "CRITICAL", "NORMAL", "MINOR", "TRIVIAL", "STABILITY", "DEBUG"].map((item) => ({
                                                                                value: item,
                                                                                label: item,
                                                                            }))}
                                                                        />
                                                                    </Descriptions.Item>
                                                                    <Descriptions.Item label="相关人员"
                                                                                       span={3}
                                                                                       style={{textAlign: "left"}}>
                                                                        {this.state.EditingStatus ?
                                                                            <Select
                                                                                mode="multiple"
                                                                                value={this.state.Users}
                                                                                onChange={(value, option) => {
                                                                                    this.setState({
                                                                                        Users: value
                                                                                    })
                                                                                }}
                                                                                style={{width: '100%'}}
                                                                                placeholder="请选择人员"
                                                                                options={this.state.userList}
                                                                            /> : <Avatar.Group
                                                                                maxCount={10}
                                                                                size={"default"}
                                                                                maxStyle={{
                                                                                    color: '#6d45c2',
                                                                                    backgroundColor: '#fde3cf',
                                                                                }}
                                                                            >
                                                                                {(this.state.Users).map((value, index) => {
                                                                                    for (let i = 0; i < this.state.userList.length; i++) {
                                                                                        if (value === this.state.userList[i].value) {
                                                                                            return this.state.userList[i].label1
                                                                                        }
                                                                                    }
                                                                                })}
                                                                            </Avatar.Group>}
                                                                    </Descriptions.Item>
                                                                    <Descriptions.Item label="配置信息"
                                                                                       contentStyle={{textAlign: "left"}}>
                                                                        <Space style={{width: "100%"}}>
                                                                            {this.state.EditingStatus ?
                                                                                <div>
                                                                                    <Input.TextArea
                                                                                        disabled={!this.state.EditingStatus}
                                                                                        bordered={this.state.EditingStatus}
                                                                                        onChange={(e) => {
                                                                                            this.setState({
                                                                                                TaskConfig: e.target.value
                                                                                            })
                                                                                        }}
                                                                                        rows={20}
                                                                                        defaultValue={typeof this.state.TaskConfig === "object" ? JSON.stringify(this.state.TaskConfig) : this.state.TaskConfig}
                                                                                        style={{width: "50vh"}}/>
                                                                                </div> : null
                                                                            }
                                                                            <div>
                                                                                <JsonTools
                                                                                    jsonData={this.state.TaskConfig}
                                                                                    collapsed={4}/>
                                                                            </div>
                                                                        </Space>
                                                                    </Descriptions.Item>
                                                                </Descriptions>
                                                            </Badge.Ribbon>
                                                        </Card>,
                                                    },
                                                    {
                                                        key: '2',
                                                        disabled: this.state.TaskType === 7,
                                                        label: (<Space size={1}><IconFont
                                                            type="icon-python"/>
                                                            <div style={{
                                                                marginLeft: -5,
                                                                fontWeight: 800
                                                            }}>用例列表
                                                            </div>
                                                        </Space>),
                                                        children: <Card
                                                            // title={<div style={{height: 25}}>用例列表</div>}
                                                            // type={"inner"}
                                                            extra={
                                                                <Space size={1}>
                                                                    <Divider type="vertical"/>
                                                                    <Button type={"link"}
                                                                            style={{
                                                                                width: 90,
                                                                                color: '#707272'
                                                                            }}
                                                                            size={"small"}
                                                                            onClick={() => {
                                                                                this.gettesttaskcases()
                                                                            }}>
                                                                        <Space size={5}>
                                                                            <RetweetOutlined/>
                                                                            <div
                                                                                style={{
                                                                                    fontWeight: 800,
                                                                                    fontSize: 12
                                                                                }}>获取用例
                                                                            </div>
                                                                        </Space>
                                                                    </Button>
                                                                    <Divider type="vertical"/>
                                                                    <Popconfirm
                                                                        title="删除用例"
                                                                        description="确定要删除该任务下的所有用例吗?"
                                                                        onConfirm={() => {
                                                                            message.success('删除所有用例数据～')
                                                                            this.deltesttaskcases(null, 2)
                                                                        }}
                                                                        okText="Yes"
                                                                        cancelText="No"
                                                                    >
                                                                        <Button type={"link"}
                                                                                style={{width: 90}}
                                                                                danger
                                                                                size={"small"}>
                                                                            <Space size={5}>
                                                                                <DeleteOutlined/>
                                                                                <div
                                                                                    style={{
                                                                                        fontWeight: 800,
                                                                                        fontSize: 12
                                                                                    }}>删除全部
                                                                                </div>
                                                                            </Space>
                                                                        </Button>
                                                                    </Popconfirm>
                                                                    <Divider type="vertical"/>
                                                                </Space>}
                                                        >
                                                            <Table
                                                                size={"middle"}
                                                                bordered={true}
                                                                columns={CaseListColumns}
                                                                dataSource={this.state.CasesList}
                                                                scroll={{
                                                                    x: 1500,
                                                                    y: 500
                                                                }}
                                                                pagination={{
                                                                    defaultPageSize: 20,
                                                                    position: ['bottomRight'],
                                                                    pageSizeOptions: [20, 100],
                                                                    responsive: true,
                                                                    size: "middle",
                                                                    total: this.state.CasesListTotal,
                                                                    showTotal: (total, range) => {
                                                                        return `第 ${range[0]}-${range[1]} 条 / 共 ${total} 条`
                                                                    },
                                                                    onShowSizeChange: (current, size) => {
                                                                        this.setState({
                                                                            CaseListPage: current,
                                                                            CaseListPageSize: size,
                                                                        }, function () {
                                                                            this.gettesttaskcases()
                                                                        })
                                                                    },
                                                                    onChange: (page, pageSize) => {
                                                                        this.setState({
                                                                            CaseListPage: page,
                                                                            CaseListPageSize: pageSize,
                                                                        }, function () {
                                                                            this.gettesttaskcases()
                                                                        })
                                                                    }
                                                                }}
                                                            />
                                                        </Card>
                                                    },
                                                    {
                                                        key: '3',
                                                        label: (<Space size={1}><SyncOutlined spin/>
                                                            <div style={{
                                                                marginLeft: -5,
                                                                fontWeight: 800
                                                            }}>任务列表
                                                            </div>
                                                        </Space>),
                                                        children:
                                                            <div>
                                                                {this.state.TaskType === 7 ?
                                                                    <Card
                                                                        extra={<Space size={1}>
                                                                            <Divider type="vertical"/>
                                                                            <Button type={"link"}
                                                                                    style={{
                                                                                        width: 90,
                                                                                        color: '#707272',
                                                                                        fontWeight: 800
                                                                                    }}
                                                                                    size={"small"}
                                                                                    onClick={() => {
                                                                                        message.success("任务列表已刷新~")
                                                                                        this.gettesttaskbuilds()
                                                                                    }}>
                                                                                <Space size={5}>
                                                                                    <RetweetOutlined/>
                                                                                    <div
                                                                                        style={{
                                                                                            fontWeight: 800,
                                                                                            fontSize: 12
                                                                                        }}>获取任务
                                                                                    </div>
                                                                                </Space>
                                                                            </Button>
                                                                            <Divider type="vertical"/>
                                                                        </Space>
                                                                        }
                                                                        title={
                                                                            <div style={{
                                                                                fontSize: 12,
                                                                                color: "red",
                                                                                fontWeight: 700,
                                                                                textAlign: "center"
                                                                            }}>
                                                                                <Space>
                                                                                    本地化任务ID「{this.state.CheckCardValue}」
                                                                                </Space>
                                                                            </div>}>
                                                                        <TaskListLocalTable
                                                                            datas={this.state.TaskBuilds}
                                                                            Total={this.state.TaskBuildsTotal}
                                                                            setBuildsPageSize={this.setBuildsPageSize}
                                                                            CheckCardValue={this.state.CheckCardValue}
                                                                            fun={this.gettesttaskbuilds}
                                                                        />
                                                                    </Card> :
                                                                    <Card
                                                                        extra={<Space size={1}>
                                                                            <Button type={"link"}
                                                                                    style={{
                                                                                        width: 90,
                                                                                        color: '#707272',
                                                                                        fontWeight: 800
                                                                                    }}
                                                                                    size={"small"}
                                                                                    onClick={() => {
                                                                                        message.success("任务列表已刷新~")
                                                                                        this.gettesttaskbuilds()
                                                                                    }}>
                                                                                <Space size={5}>
                                                                                    <RetweetOutlined/>
                                                                                    <div
                                                                                        style={{
                                                                                            fontWeight: 800,
                                                                                            fontSize: 12
                                                                                        }}>获取任务
                                                                                    </div>
                                                                                </Space>
                                                                            </Button>
                                                                            <Divider type="vertical"/>
                                                                            <Popconfirm

                                                                                title="测试任务状态同步"
                                                                                description={<div style={{
                                                                                    fontSize: 12,
                                                                                    color: "red"
                                                                                }}>确定该测试任务状态异常,需要执行状态同步操作?</div>}
                                                                                onConfirm={() => {
                                                                                    this.synctaskbuildsstatus()
                                                                                }}
                                                                                okText="Yes"
                                                                                cancelText="No"
                                                                            >
                                                                                <Button type={"link"}
                                                                                        style={{
                                                                                            width: 90,
                                                                                            color: '#707272',
                                                                                        }}
                                                                                        size={"small"}>
                                                                                    <Space size={5}>
                                                                                        <CloudSyncOutlined/>
                                                                                        <div
                                                                                            style={{
                                                                                                fontWeight: 800,
                                                                                                fontSize: 12
                                                                                            }}>状态同步
                                                                                        </div>
                                                                                    </Space>
                                                                                </Button>
                                                                            </Popconfirm>
                                                                            <Divider type="vertical"/>
                                                                            <Popconfirm

                                                                                title="删除Jenkins构建任务"
                                                                                description={<div style={{
                                                                                    fontSize: 12,
                                                                                    color: "red"
                                                                                }}>确定要删除该任务下的所有构建任务以及测试报告数据吗?</div>}
                                                                                onConfirm={() => {
                                                                                    this.deltesttaskbuilds()
                                                                                }}
                                                                                okText="Yes"
                                                                                cancelText="No"
                                                                            >
                                                                                <Button type={"link"}
                                                                                        style={{width: 90}}
                                                                                        size={"small"}
                                                                                        danger
                                                                                >
                                                                                    <Space size={5}>
                                                                                        <DeleteOutlined/>
                                                                                        <div
                                                                                            style={{
                                                                                                fontWeight: 800,
                                                                                                fontSize: 12
                                                                                            }}>删除构建
                                                                                        </div>
                                                                                    </Space>
                                                                                </Button>
                                                                            </Popconfirm>
                                                                            <Divider type="vertical"/>
                                                                        </Space>}
                                                                        style={{
                                                                            flexGrowth: 1,
                                                                            height: '100%',
                                                                            width: '100%',
                                                                        }}>
                                                                        <TaskListBuildsTable
                                                                            datas={this.state.TaskBuilds}
                                                                            Total={this.state.TaskBuildsTotal}
                                                                            setBuildsPageSize={this.setBuildsPageSize}
                                                                            CheckCardValue={this.state.CheckCardValue}
                                                                            fun={this.gettesttaskbuilds}
                                                                            BuildsTablePage={this.state.BuildsPage}
                                                                            BuildsTablePageSize={this.state.BuildsPageSize}
                                                                        />
                                                                    </Card>}
                                                            </div>
                                                    },
                                                    {
                                                        key: '4',
                                                        disabled: this.state.TaskType === 7,
                                                        label: (<Space size={1}>
                                                            <GitlabOutlined/>
                                                            <div style={{
                                                                marginLeft: -5,
                                                                fontWeight: 800
                                                            }}>环境配置
                                                            </div>
                                                        </Space>),
                                                        children: <TaskEnvConfig
                                                            datas={this.state.JenkinsConfig}
                                                            fun={this.gettesttaskconfig}/>
                                                    },
                                                    {
                                                        key: '5',
                                                        label: (<Space size={1}>
                                                            <QuestionCircleOutlined/>
                                                            <div style={{
                                                                marginLeft: -5,
                                                                fontWeight: 800
                                                            }}>常见问题
                                                            </div>
                                                        </Space>),
                                                        children: <HelpPageHome/>
                                                    }
                                                ]}
                                            />
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                            :
                            <Row>
                                <Col span={24}>
                                    <Empty
                                        style={{
                                            background: 'rgb(240, 242, 245)',
                                            height: 563,
                                        }}
                                        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                        imageStyle={{
                                            height: 80,
                                            marginTop: 200,
                                        }}
                                        description={
                                            <div>
                                                <div style={{
                                                    fontSize: 13,
                                                    color: "#a1a6a6"
                                                }}>暂无测试任务数据,创建您的第一个测试任务吧～
                                                </div>
                                                <br/>
                                                <CreateTT btn_type={false} datas={{
                                                    ProjectId: this.props.match.params.id
                                                }} fun1={this.gettesttask}/>
                                            </div>
                                        }/>
                                </Col>
                            </Row>
                        }
                    </div>
                </Spin>
            </Watermark>
        </>
    }
}
